var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.isEdit ? "编辑关系" : "添加关系",
        visible: _vm.open,
        maskClosable: false,
        width: "600px",
        destroyOnClose: "",
      },
      on: { ok: _vm.save, cancel: _vm.cancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "a-form-model",
        _vm._b(
          {
            ref: "dataForm",
            staticStyle: { "padding-bottom": "70px" },
            attrs: { layout: "horizontal", model: _vm.formData },
            on: { mouseover: _vm.topCenterMouseover },
          },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "人物", prop: "originName" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "show-search": "",
                    placeholder: "请选择人物",
                    "option-filter-prop": "label",
                    "filter-option": _vm.filterOption,
                  },
                  model: {
                    value: _vm.formData.anotherTermId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "anotherTermId", $$v)
                    },
                    expression: "formData.anotherTermId",
                  },
                },
                _vm._l(_vm.roleList, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "关系", prop: "processedName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入关系说明" },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "敬语/平语", prop: "debut" } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          staticStyle: { width: "100%" },
                          on: {
                            change: (value) =>
                              _vm.radioChange(value, _vm.formData),
                          },
                          model: {
                            value: _vm.formData.honorific,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "honorific", $$v)
                            },
                            expression: "formData.honorific",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: "敬语" } }, [
                            _vm._v("敬语"),
                          ]),
                          _c("a-radio", { attrs: { value: "平语" } }, [
                            _vm._v("平语"),
                          ]),
                          _c("a-radio", { attrs: { value: "其他" } }, [
                            _vm._v("其他"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 10 } },
                    [
                      _vm.formData.honorific == "其他"
                        ? _c("a-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formData.elseInfo,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "elseInfo", $$v)
                              },
                              expression: "formData.elseInfo",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "称呼", prop: "description" } },
            [
              _c("a-textarea", {
                attrs: {
                  "auto-size": { minRows: 1, maxRows: 10 },
                  placeholder: "请输入称呼",
                },
                model: {
                  value: _vm.formData.call,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "call", $$v)
                  },
                  expression: "formData.call",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { on: { mouseover: _vm.topCenterMouseover } },
          [
            _c("a-button", { key: "back", on: { click: _vm.cancel } }, [
              _vm._v("\n        取消\n      "),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary" },
                on: { click: _vm.save },
              },
              [_vm._v("\n        确定\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }